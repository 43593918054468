import styled from '@emotion/styled';
import { BANNER_OPACITY } from '../../../utils/common';

export const MiddleBodyVideoBackground = styled.div`
  background: ${(props) =>
    !props?.propsBackground?.content[0]?.url && `none, ${props?.themeBackground}`};
  background-size: cover;
  background-position: center;
  flex-direction: column;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; /* Set the z-index behind other content */
  }

  /* Add a linear gradient overlay */
  &::after {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: ${BANNER_OPACITY};
  }
  @media screen and (min-width: 768px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  @media screen and (max-width: 767px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }

  & > video {
    position: absolute;
    height: 100%;
  }
`;
export const MiddleBodyParent = styled.div`
  display: flex;
  width: 100%;
  z-index: 2;
  flex-direction: column;
  &.bg_type_image {
    background: ${(props) =>
      props?.propsBackground?.content[0]?.url
        ? `url(${props?.propsBackground?.content[0]?.url})`
        : `none, ${props?.themeBackground}`};
    background-size: cover;
    background-position: center;
  }

  &.bg_type_solid {
    background: ${(props) => props?.themeBackground};
  }

  @media screen and (min-width: 768px) {
    gap: 4rem;
    padding: 4rem;
  }
  @media screen and (max-width: 767px) {
    gap: 1.5rem;
    padding: 2rem 1rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: 768px) {
    gap: 4rem;
  }
  @media screen and (max-width: 767px) {
    gap: 0;
  }
`;

export const TopLeftSection = styled.div`
  flex: 1 0 auto;
  width: 100%;
  & > h1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    color: ${(props) => props?.textStyles?.heading?.color};
    font-family: ${(props) => props?.textStyles?.heading?.fontFamily || 'Arial'};
    text-align: ${(props) => props?.textStyles?.heading?.textAlign || 'left'};
  }

  @media screen and (min-width: 768px) {
    & > h1 {
      font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.25rem'};
      font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
      line-height: 3.875rem;
    }
  }
  @media screen and (max-width: 767px) {
    & > h1 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

export const TopRightSection = styled.div`
  flex: 0;
  width: 0;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const BottomSection = styled.div`
  .grid-container {
    display: grid;
  }
  .grid-item {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 3/4;
  }

  .image-text {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: ${(props) => props?.textStyles?.body?.color};
    text-align: ${(props) => props?.textStyles?.body?.textAlign || 'center'};
    font-family: ${(props) => props?.textStyles?.body?.fontFamily || 'Arial'};
    font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.125rem'};
    font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }
  @media screen and (min-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr); /* Columns fill available space */
      grid-row-gap: 4rem;
      grid-column-gap: 3.563rem;
    }
  }
  @media screen and (max-width: 767px) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr); /* Single column for screens below 768px */
      grid-row-gap: 22.72px;
    }
  }
`;
